import React from "react"
import { Breadcrumb, SectionNameClienti, ToolbarClienti } from "../components/toolbar"
import { TabellaClienti } from "../components/tabella"
import { LayoutTabella } from "../components/layout"
import Seo from "../components/seo"

const ClientiPage = () => {
    const BreadcrumbClienti = 
        () => (
            <Breadcrumb 
                arrayLink={[
                    {uri: '/clienti', titolo: 'Clienti'}
                ]}
            />
        )

    return (
        <LayoutTabella 
            Breadcrumb={BreadcrumbClienti}
            SectionName={SectionNameClienti}
            Toolbar={ToolbarClienti}
            Tabella={TabellaClienti}
        >
            <Seo title="Clienti" />
        </LayoutTabella>
    )
}

export default ClientiPage
